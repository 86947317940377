import React from "react";

function Tmeet(props) {
  return (
    <div>
      <div>
        <ul className="leave-list">
          {props.todaysLeave.length > 0 ? (
            props.todaysLeave.map((row, index) => (
              <li>
                <div className="emp-details">
                  <img
                    className="emplv"
                    // src={
                    //   row.image !== "" && row.image !== null
                    //     ? "https://crm.nablasol.net/fromAPI/" + row.image
                    //     : row.gender === "Male"
                    //     ? "https://crm.nablasol.net/fromAPI/male.png"
                    //     : "https://crm.nablasol.net/fromAPI/female.png"
                    // }
                    src={
                      row.image !== "" && row.image !== null
                        ? "https://crm.nablasol.net/fromAPI/" +
                        row.image
                        : row.gender === "Male"
                          ? "https://crm.nablasol.net/fromAPI/male.png"
                          : "https://crm.nablasol.net/fromAPI/female.png"
                    }
                    alt={
                      row.first_name.charAt(0).toUpperCase() +
                      row.first_name.slice(1).toLowerCase()
                    }
                  />
                  <p>
                    {row.first_name.charAt(0).toUpperCase() +
                      row.first_name.slice(1).toLowerCase()}
                    <span className="lvemp-designation">{row.designation}</span>
                  </p>
                </div>
                <span className="emp-lv-dt"> {row.leave_type_c=='full day'?'Full Day':''} {row.leave_type_c=='second half'?'2nd Half':''} {row.leave_type_c=='first half'?'1st Half':''} </span>
              </li>
            ))
          ) : (
            <div className="norecords-display">No Employee On Leave Today!</div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Tmeet;
