import "./App.css";
import React, { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
//import Main from "./components/Main";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import TaskMgmt from "./components/TaskMgmt";
import ProdTaskManagement from "./components/ProdTaskManagement";
import Personal from "./components/Personal";
import Team from "./components/Team";
import Records from "./components/Records";
import Holiday from "./components/Holiday";
import Qsr from "./components/Qsr";
import LeavePolicy from "./components/LeavePolicy";
import Terms from "./components/Terms";
import Error from "./components/Error";
import Clients from "./components/Clients";
import BdayWishes from "./components/BdayWishes";
import Reports from "./components/Reports";
import WorkReportDetails from "./components/WorkReportDetails";
import WorkReportAggregate from "./components/WorkReportAggregate";
import ClientTimesheet from "./components/ClientTimesheet";
import ClientTimesheetCtax from "./components/ClientTimesheetCtax";
import Employees from "./components/Employees";
import TrelloTasks from "./components/TrelloTasks";
import AllClients from "./components/AllClients";
import TrelloTasksClients from "./components/TrelloTasksClients";
import AdminAccessRoutes from "./components/AdminAccessRoutes";
import CtaxReport from "./components/CtaxReport";
import CtaxProjectTasks from "./components/CtaxProjectTasks";
import TaskBoard from "./components/TaskBoard";
import TrainingModule from "./components/TrainingModule";
import EmployeeCompletedTrainings from "./components/EmployeeCompletedTrainings";
import EmployeeUpcomingTrainings from "./components/EmployeeUpcomingTrainings";
import TrainingAssessment from "./components/TrainingAssessment";
import TrainingDetails from "./components/TrainingDetails";

export const LoginManageContext = createContext();

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const userId = localStorage.getItem("userId");
  const [loginData, setLoginData] = useState(userInfo);
  const [userRole, setUserRole] = useState("");

  function updateData(response) {
    // console.log('update',response);
    setLoginData(response);
    setUserRole(response.userData.user_role_c);
  }

  if (
    userId === null ||
    userId === 0 ||
    userId === "0" ||
    userId === undefined
  ) {
    return <Login />;
    //navigate('/');
  }
  return (
    <>
      <LoginManageContext.Provider
        value={{ loginData, setLoginData, updateData }}
      >
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route
            exact
            path="/dashboard"
            element={<Dashboard name="dashboard" />}
          />
          <Route
            exact
            path="/manage-tasks"
            element={<TaskMgmt name="tasks" />}
          />
          {/* {loginData?.userData?.companyemail ===
            "sourabh.patial@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "dhirendra.kumar@nablasol.com" ||
          loginData?.userData?.companyemail === "gurpreet.modi@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "manjeet.sharma@nablasol.com" ? (
            <Route
              exact
              path="/manage-prod-tasks"
              element={<ProdTaskManagement name="prod-tasks" />}
            />
          ) : null} */}
          <Route
            exact
            path="/employee-info"
            element={<Personal name="Personal" />}
          />
          <Route exact path="/team-members" element={<Team />} />
          <Route exact path="/records" element={<Records />} />
          <Route exact path="/holiday" element={<Holiday />} />
          <Route exact path="/quarterly-self-review" element={<Qsr />} />
          <Route exact path="/leave-policy" element={<LeavePolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route exact path={`/team-members/:userId`} element={<Clients />} />
          <Route exact path={`/manage-tasks/:showTasks`} element={<TaskMgmt />} />
          <Route path="*" element={<Error />} />
          <Route exact path="/bday-wishes" element={<BdayWishes />} />
          <Route exact path="/training" element={<TrainingModule />} />
          <Route
            exact
            path="/training-assessment"
            element={<TrainingAssessment />}
          />
          <Route
            exact
            path="/training-details/:id"
            element={<TrainingDetails />}
          />
          <Route
            exact
            path="/reports"
            element={
              <AdminAccessRoutes>
                <Reports />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/work-report-details"
            element={
              <AdminAccessRoutes>
                <WorkReportDetails />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/work-report-aggregate"
            element={
              <AdminAccessRoutes>
                <WorkReportAggregate />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/client-timesheet"
            element={
              <AdminAccessRoutes>
                <ClientTimesheet />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/client-timesheet-ctax"
            element={
              <AdminAccessRoutes>
                <ClientTimesheetCtax />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/trello-tasks-employee"
            element={
              <AdminAccessRoutes>
                <Employees link="trello-tasks" />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/upcoming-trainings-employee"
            element={
              <AdminAccessRoutes>
                <Employees link="upcoming-trainings" />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/completed-trainings-employee"
            element={
              <AdminAccessRoutes>
                <Employees link="completed-trainings" />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/trello-tasks/:userId`}
            element={
              <AdminAccessRoutes>
                <TrelloTasks />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/upcoming-trainings/:userId`}
            element={
              <AdminAccessRoutes>
                <EmployeeUpcomingTrainings />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/completed-trainings/:userId`}
            element={
              <AdminAccessRoutes>
                <EmployeeCompletedTrainings />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path="/trello-tasks-clients"
            element={
              <AdminAccessRoutes>
                <AllClients />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/trello-tasks-client/:clientId`}
            element={
              <AdminAccessRoutes>
                <TrelloTasksClients />
              </AdminAccessRoutes>
            }
          />

          <Route
            exact
            path={`/ctax-report`}
            element={
              <AdminAccessRoutes>
                <CtaxReport />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/ctax-report/:projectId`}
            element={
              <AdminAccessRoutes>
                <CtaxProjectTasks />
              </AdminAccessRoutes>
            }
          />
          <Route
            exact
            path={`/ctax-task-board`}
            element={
              <AdminAccessRoutes>
                <TaskBoard />
              </AdminAccessRoutes>
            }
          />

        </Routes>
      </LoginManageContext.Provider>
    </>
  );
}

export default App;
