import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Alert from "./Alert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import swal from "sweetalert";
const Award = (props) => {
  return (
    <div className="award-container">
      <div className="award">
        <img src={props.src} alt={props.award} />
        <p>{props.children}</p>
      </div>
    </div>
  );
};

const Qsr = () => {
  const [alertmsg, setAlertMsg] = useState({
    msg: "",
    errorStatus: "",
    alertText: "",
  });
  const [loader, setLoader] = useState(false);
  const currYear = new Date().getFullYear();
  const uId = localStorage.getItem("userId");
  const uInfo = localStorage.getItem("userinfo");
  // var qsrMonths = "";
  var qsrValue = "";
  // const month = new Date().getMonth() + 1;
  const [show, setShow] = useState(false);
  const [qsr, setQsr] = useState(false);
  const [qsrMonths, setQsrMonths] = useState([]);
  const [wallOfFame, setWallOfFame] = useState({});
  // get getQsrInfo info
  const getQsrInfo = () => {
    const qsrMonths = displayMonth();
    axios
      .post("/getQsrInfoApi.php", {
        qsr_months: qsrMonths,
        year: currYear,
        uId: uId,
      })
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200 && res.result.length > 0) {
          setQsr({
            ...qsr,
            review_points: res.result[0].review_points_c,
            qsr_months: res.result[0].qsr_months_c,
            id: res.result[0].id,
          });
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // to set user data in state
  const setUserInfo = (e) => {
    setQsr({
      ...qsr,
      [e.target.name]: e.target.value,
      year: currYear,
      uId: uId,
    });
  };
  const modalOpen = () => {
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const saveReview = (e) => {
    setLoader(true);
    e.preventDefault();
    axios
      .post("/quaterlySelfReviewApi.php", qsr)
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          // setAlertMsg({
          //   ...alertmsg,
          //   msg: "Quarterly self review submitted successfully.",
          //   errorStatus: "success",
          //   alertText: "on",
          // });
          swal({
            // title: "Good job!",
            text: "Quarterly self review submitted successfully.",
            icon: "success",
          });
          setLoader(false);
          // alert("You have submited quaterly self review successfully");
          setQsr({ ...qsr, id: res.data });
        } else {
          // setAlertMsg({
          //   ...alertmsg,
          //   msg: "Oops! Something went wrong. Try again.",
          //   errorStatus: "error",
          //   alertText: "on",
          // });
          swal({
            title: "Oops!",
            text: "Oops! Something went wrong. Try again.",
            icon: "error",
          });
          // alert("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // update review
  const updateReview = (e) => {
    setLoader(true);
    e.preventDefault();
    axios
      .post("/updateQuaterlySelfReviewApi.php", qsr)
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          // setAlertMsg({
          //   ...alertmsg,
          //   msg: "Quarterly self review submitted successfully.",
          //   errorStatus: "success",
          //   alertText: "on",
          // });
          swal({
            //title: "Oops!",
            text: "Quarterly self review updated successfully.",
            icon: "success",
          });
          setLoader(false);
          // alert("You have submited quaterly self review successfully");
          setQsr({ ...qsr, id: res.data });
        } else {
          setAlertMsg({
            ...alertmsg,
            msg: "Oops! Something went wrong. Try again.",
            errorStatus: "error",
            alertText: "on",
          });
          // alert("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const displayMonth = () => {
    var prevMonths = [];
    const month = new Date().getMonth() + 1;
    var currMonth = "";
    if (month >= 0) {
      prevMonths.push({ label: "Jan - Mar", value: "Jan_Mar" });
      currMonth = "Jan_Mar";
    }
    if (month >= 4) {
      prevMonths.push({ label: "Apr - June", value: "Apr_June" });
      currMonth = "Apr_June";
    }
    if (month >= 8) {
      prevMonths.push({ label: "July - Sept", value: "July_Sept" });
      currMonth = "July_Sept";
    }
    if (month >= 10) {
      prevMonths.push({ label: "Oct - Dec", value: "Oct_Dec" });
      currMonth = "Oct_Dec";
    }
    setQsr({
      ...qsr,
      qsr_months: currMonth,
    });
    setQsrMonths(prevMonths);
    return currMonth;
  };

  // get wall of fame
  const getWallOfFame = () => {
    axios
      .get("/getWallOfFameApi.php")
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          setWallOfFame(res.result);
        } else {
          alert("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFeedback = async (months_) => {
    if (qsrMonths[qsrMonths.length - 1]?.value === months_) {
      setQsr({ ...qsr, qsr_months: months_ });
      return;
    }
    try {
      const response = await axios.post("/getQsrInfoApi.php", {
        qsr_months: months_,
        year: currYear,
        uId: uId,
      });
      const res = response.data;

      if (res.statusCode === 200 && res.result.length > 0) {
        setQsr({
          ...qsr,
          qsr_months: months_,
          general_review: res.result[0].review_points_c,
          quality: res.result[0].quality_c,
          improvement: res.result[0].improvement_c,
        });
      } else {
        // if (months_ == "Apr_June") {
        //   setQsr({
        //     ...qsr,
        //     qsr_months: months_,
        //     general_review: res.result[0].review_points_c,
        //     quality: res.result[0].quality_c,
        //     improvement: res.result[0].improvement_c,
        //   });
        // }
        // alert("Sorry, you haven't submitted self-review last quarter.");
        swal({
          title: "Oops!",
          text: "Seems like you haven't submitted your self-review for this quarter.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQsrInfo();
    displayMonth();
    getWallOfFame();
  }, []);

  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="qsr" className="main-view">
            <div className="view-head">
              <h6> Quarterly Self-Review </h6>
              <button className="nbl-btn" onClick={modalOpen}>
                Awards
              </button>
            </div>
            <div className="view-grid">
              <div className="qsr-submit grid-dashlet">
                <div className="qsr-submit">
                  {/* <form onSubmit={saveReview} method="POST"> */}

                  <form
                    onSubmit={(e) => {
                      qsr.id ? updateReview(e) : saveReview(e);
                    }}
                    method="POST"
                  >
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-6">
                        <label> Year </label>
                        <input
                          required
                          type="text"
                          readOnly
                          value={currYear}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6">
                        <label> Months </label>
                        <select
                          required
                          name="qsr_months"
                          value={qsr?.qsr_months}
                          onChange={async (e) => {
                            await getFeedback(e.target.value);
                          }}
                          className="form-control"
                        >
                          {qsrMonths.map((month) => {
                            return (
                              <option value={month.value}>
                                {" "}
                                {month.label}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {qsrMonths[qsrMonths.length - 1]?.value ===
                      qsr?.qsr_months ? (
                      <>
                        <div className="mt-4 self_review">
                          {alertmsg.alertText === "on" ? (
                            <Alert alertText={alertmsg} />
                          ) : (
                            ""
                          )}
                          <label> Review Points </label>
                          <span className="qsr-pts text-danger d-block mb-3">
                            (a) Projects/Tasks Completed
                            &nbsp;&nbsp;&nbsp;&nbsp;(b) Special
                            Accomplishments/Learnings
                          </span>
                          <textarea
                            required
                            className="form-control"
                            name="review_points"
                            onChange={setUserInfo}
                            placeholder="Write your review summary here..."
                            rows="7"
                            value={qsr.review_points}
                          ></textarea>
                        </div>
                        {loader === true ? (
                          <div className="loader">
                            <img src="/img/hrm-loader.svg" alt="loader" />
                          </div>
                        ) : (
                          ""
                        )}
                        {qsr.id ? (
                          <button type="submit" className="nbl-btn">
                            {" "}
                            Update Review
                          </button>
                        ) : (
                          <button type="submit" className="nbl-btn">
                            {" "}
                            Submit Review
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="feedback_form mt-4">
                          <label>Performance Overview</label>
                          <textarea
                            required
                            className="form-control"
                            name="general_review"
                            // onChange={getFeedback}
                            rows="7"
                            value={qsr.general_review}
                          ></textarea>
                          <div className="feedback_inner">
                            <div className="feedback_points">
                              <label>Achievements and Qualities</label>
                              <textarea
                                required
                                className="form-control"
                                name="quality"
                                rows="7"
                                disabled="disabled"
                                value={qsr.quality}
                              ></textarea>
                            </div>
                            <div className="feedback_points">
                              <label>Areas of Development</label>
                              <textarea
                                required
                                className="form-control"
                                name="improvement_c"
                                rows="7"
                                disabled="disabled"
                                value={qsr.improvement}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <h5 className="text-center pb-2">Wall Of Fame {currYear} </h5>
                <div className="qtr-wrapper">
                  <p className="qtr-name"> Jan - Mar </p>
                  <div className="qtr">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].jan_mar.map((row, index) => (
                        <div className="emp-wrapper">
                          <img
                            src={
                              row.image !== "" && row.image !== null
                                ? "https://crm.nablasol.net/fromAPI/" +
                                row.image
                                : row.gender === "Male"
                                  ? "https://crm.nablasol.net/fromAPI/male.png"
                                  : "https://crm.nablasol.net/fromAPI/female.png"
                            }
                          />
                          <div className="awardee-details">
                            <p>{row.first_name + " " + row.last_name}</p>{" "}
                            <span className="award-name">
                              {row.award_name}
                            </span>
                          </div>
                        </div>
                      ))
                      : ""}
                  </div>

                  <p className="qtr-name">
                    {" "}
                    {wallOfFame.length > 0
                      ? wallOfFame[0].Apr_June.length > 0
                        ? "APR - JUN"
                        : ""
                      : ""}{" "}
                  </p>
                  <div className="qtr">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].Apr_June.map((row, index) => (
                        <div className="emp-wrapper">
                          <img
                            src={
                              row.image !== "" && row.image !== null
                                ? "https://crm.nablasol.net/fromAPI/" +
                                row.image
                                : row.gender === "Male"
                                  ? "https://crm.nablasol.net/fromAPI/male.png"
                                  : "https://crm.nablasol.net/fromAPI/female.png"
                            }
                          />
                          <div className="awardee-details">
                            <p>{row.first_name + " " + row.last_name}</p>{" "}
                            <span className="award-name">
                              {row.award_name}
                            </span>
                          </div>
                        </div>
                      ))
                      : ""}
                  </div>
                  <p className="qtr-name">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].July_Sep.length > 0
                        ? "Jul - Sept"
                        : ""
                      : ""}{" "}
                  </p>
                  <div className="qtr">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].July_Sep.map((row, index) => (
                        <div className="emp-wrapper">
                          <img
                            src={
                              row.image !== "" && row.image !== null
                                ? "https://crm.nablasol.net/fromAPI/" +
                                row.image
                                : row.gender === "Male"
                                  ? "https://crm.nablasol.net/fromAPI/male.png"
                                  : "https://crm.nablasol.net/fromAPI/female.png"
                            }
                          />
                          <div className="awardee-details">
                            <p>{row.first_name + " " + row.last_name}</p>{" "}
                            <span className="award-name">
                              {row.award_name}
                            </span>
                          </div>
                        </div>
                      ))
                      : ""}
                  </div>
                  <p className="qtr-name">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].Oct_Dec.length > 0
                        ? "Oct - Dec"
                        : ""
                      : ""}{" "}
                  </p>
                  <div className="qtr">
                    {wallOfFame.length > 0
                      ? wallOfFame[0].Oct_Dec.map((row, index) => (
                        <div className="emp-wrapper">
                          <img
                            src={
                              row.image !== "" && row.image !== null
                                ? "https://crm.nablasol.net/fromAPI/" +
                                row.image
                                : row.gender === "Male"
                                  ? "https://crm.nablasol.net/fromAPI/male.png"
                                  : "https://crm.nablasol.net/fromAPI/female.png"
                            }
                          />
                          <div className="awardee-details">
                            <p>{row.first_name + " " + row.last_name}</p>{" "}
                            <span className="award-name">
                              {row.award_name}
                            </span>
                          </div>
                        </div>
                      ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={show}
              onHide={modalClose}
              dialogClassName="add-trello-task"
              aria-labelledby="Add Trello Task"
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title as="h5">
                  {" "}
                  Employee Recognition Program{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="award-wrapper">
                  <Award
                    src="/img/sleepless_samurai.png"
                    award="Sleepless Samurai"
                  >
                    You get this award if you are a true embodiment of diligent
                    <span className="qsr-color"> hard work </span>. We honor you
                    for outperforming the rest of the warriors at Nablasol.
                  </Award>

                  <Award
                    src="/img/stellar_swordmaster.png"
                    award="Stellar Swordmaster"
                  >
                    You get this award if your passion & strive to keep learning
                    has inspired us. We honor your
                    <span className="qsr-color"> growth and expertise </span> in
                    various fields here at Nablsaol.
                  </Award>

                  <Award src="/img/supreme_scholar.png" award="Supreme Scholar">
                    You get this award if you have shown the true meaning of
                    <span className="qsr-color"> allegiance </span>. We humbly
                    honor you for standing beside us at Nablasol and{" "}
                    <span className="qsr-color">
                      guiding the way to younglings.
                    </span>
                  </Award>

                  <Award src="/img/wize_wizard.png" award="Wize Wizard">
                    You get this award if you have amazed us with your magical
                    <span className="qsr-color"> problem-solving skills</span>.
                    We honor you for your out-of-the-box thinking.
                  </Award>

                  <Award src="/img/star_sheriff.png" award="Star Sheriff">
                    You get this award if your strength to be disciplined at all
                    times is commendable. We honor you for
                    <span className="qsr-color"> upholding all values</span> and
                    being a flagbearer of
                    <span className="qsr-color"> Nablasol’s culture</span>.
                  </Award>

                  <Award src="/img/nuclear_ninja.png" award="Nuclear Ninja">
                    You get this award if you value commitment more than
                    anything else. We honor you for showing
                    <span className="qsr-color">
                      {" "}
                      resolute consistency{" "}
                    </span>{" "}
                    with your work output over time.
                  </Award>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Qsr;
