import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import axios from "axios";
import moment from 'moment';
const Calendar = () => {
  const [leaves, setLeaves] = useState([]);

  const events = leaves.map(function(item) {
    return {
      title: item.first_name,
      start: item.leave_from_c,
      end: moment(item.leave_to_c).add(1, "day").format("YYYY-MM-DD"),
      status: item.action_c,
      fullDay : item.leave_type_c,
      backgroundColor : item.action_c == "LOP" || item.action_c == "Approved" ? "#2885bb" : "orange",
      borderColor: "transparent",

      // allDay: item.leave_type_c == "full day" ? true : false
    };
  });
  // console.log("leaves ", leaves);
  // console.log(events);
  const getLeaveList = async () => {
    axios
      .post("/getAllAppliedLeaveApi.php")
      .then(function (response) {
        // console.log(response);
        if (response.status === 200) {
          setLeaves(response.data);
        } else {
          console.log("Oops someting went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });;
  };
  
  const content = (eventInfo) =>{
    // console.log("event info " , eventInfo);
    return(
      <div className='leave_label' style={{margin:"2px !important"}}>
        <b>{eventInfo.event.title}</b>
        <i style={{textTransform:"capitalize" , fontSize:"12px"}}>{eventInfo.event.extendedProps.fullDay}</i>  
      </div>
    )
  }
  useEffect(() => {
    getLeaveList();
  },[])
  return (
    <div className="App">
      <FullCalendar
        themeSystem="standard"
        plugins={[dayGridPlugin]}
        initialView= 'dayGridMonth'
        events={events}
        dayMaxEvents={2}
        eventContent={content}
        fixedWeekCount={false}
      />
      
    </div>
  )
}

export default Calendar;