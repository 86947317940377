import React from "react";
import { Navigate } from "react-router";

const AdminAccessRoutes = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  console.log(userInfo);
  const userRole = userInfo.userData.user_role_c;

  return userRole === "TL" ||
    userRole === "CEO" ||
    userRole === "HR" ||
    userRole === "PM" ||
    userRole === "BA" ||
    userRole === "Tester" ? (
    <div className="dms-pages">{children}</div>
  ) : (
    <Navigate to="/error" />
  );
};

export default AdminAccessRoutes;
