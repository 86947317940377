import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { LoginManageContext } from "./../App";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// import { Autocomplete, TextField } from "@mui/material";

const OptionalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        fill="#005c5c"
        d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 7H4v11h16zm-4.964 2.136l1.414 1.414l-4.95 4.95l-3.536-3.536L9.38 11.55l2.121 2.122z"
      ></path>
    </svg>
  );
};

const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M24 10.6667H26.6667C27.0203 10.6667 27.3594 10.8072 27.6095 11.0572C27.8595 11.3073 28 11.6464 28 12V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3333 26.6667 29.3333H5.33333C4.97971 29.3333 4.64057 29.1929 4.39052 28.9428C4.14048 28.6928 4 28.3536 4 28V12C4 11.6464 4.14048 11.3073 4.39052 11.0572C4.64057 10.8072 4.97971 10.6667 5.33333 10.6667H8V9.33334C8 7.21161 8.84285 5.17678 10.3431 3.67649C11.8434 2.1762 13.8783 1.33334 16 1.33334C18.1217 1.33334 20.1566 2.1762 21.6569 3.67649C23.1571 5.17678 24 7.21161 24 9.33334V10.6667ZM14.6667 20.976V24H17.3333V20.976C17.8417 20.6825 18.239 20.2294 18.4636 19.6871C18.6882 19.1448 18.7276 18.5435 18.5757 17.9765C18.4238 17.4095 18.089 16.9085 17.6233 16.5511C17.1576 16.1937 16.587 16 16 16C15.413 16 14.8424 16.1937 14.3767 16.5511C13.911 16.9085 13.5762 17.4095 13.4243 17.9765C13.2724 18.5435 13.3118 19.1448 13.5364 19.6871C13.761 20.2294 14.1583 20.6825 14.6667 20.976ZM21.3333 10.6667V9.33334C21.3333 7.91886 20.7714 6.5623 19.7712 5.56211C18.771 4.56191 17.4145 4.00001 16 4.00001C14.5855 4.00001 13.229 4.56191 12.2288 5.56211C11.2286 6.5623 10.6667 7.91886 10.6667 9.33334V10.6667H21.3333Z"
        fill="#666666"
      />
    </svg>
  );
};

const OverdueIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M15.9998 29.3334C8.63584 29.3334 2.6665 23.364 2.6665 16C2.6665 8.63602 8.63584 2.66669 15.9998 2.66669C23.3638 2.66669 29.3332 8.63602 29.3332 16C29.3332 23.364 23.3638 29.3334 15.9998 29.3334ZM14.6665 20V22.6667H17.3332V20H14.6665ZM14.6665 9.33335V17.3334H17.3332V9.33335H14.6665Z"
        fill="#BA1A1A"
      />
    </svg>
  );
};

const UpcomingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M9.33317 1.33331V3.99998H3.99984C3.64622 3.99998 3.30708 4.14046 3.05703 4.3905C2.80698 4.64055 2.6665 4.97969 2.6665 5.33331V26.6666C2.6665 27.0203 2.80698 27.3594 3.05703 27.6095C3.30708 27.8595 3.64622 28 3.99984 28H14.3398C12.6987 25.9503 11.8713 23.3673 12.016 20.7455C12.1607 18.1238 13.2673 15.6475 15.124 13.7908C16.9807 11.9342 19.457 10.8275 22.0787 10.6828C24.7005 10.5381 27.2835 11.3656 29.3332 13.0066V5.33331C29.3332 4.97969 29.1927 4.64055 28.9426 4.3905C28.6926 4.14046 28.3535 3.99998 27.9998 3.99998H22.6665V1.33331H19.9998V3.99998H11.9998V1.33331H9.33317ZM30.6665 21.3333C30.6665 22.3839 30.4596 23.4242 30.0575 24.3948C29.6555 25.3654 29.0662 26.2473 28.3234 26.9902C27.5805 27.733 26.6986 28.3223 25.728 28.7243C24.7574 29.1264 23.7171 29.3333 22.6665 29.3333C21.6159 29.3333 20.5756 29.1264 19.605 28.7243C18.6344 28.3223 17.7525 27.733 17.0097 26.9902C16.2668 26.2473 15.6775 25.3654 15.2755 24.3948C14.8734 23.4242 14.6665 22.3839 14.6665 21.3333C14.6665 19.2116 15.5094 17.1768 17.0097 15.6765C18.5099 14.1762 20.5448 13.3333 22.6665 13.3333C24.7882 13.3333 26.8231 14.1762 28.3234 15.6765C29.8237 17.1768 30.6665 19.2116 30.6665 21.3333ZM21.3332 16V21.8853L24.3905 24.9426L26.2758 23.0573L23.9998 20.7813V16H21.3332Z"
        fill="#FF981F"
      />
    </svg>
  );
};

const ScheduledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M2.6665 14.6667H29.3332V26.6667C29.3332 27.0203 29.1927 27.3594 28.9426 27.6095C28.6926 27.8595 28.3535 28 27.9998 28H3.99984C3.64622 28 3.30708 27.8595 3.05703 27.6095C2.80698 27.3594 2.6665 27.0203 2.6665 26.6667V14.6667ZM22.6665 4.00001H27.9998C28.3535 4.00001 28.6926 4.14049 28.9426 4.39053C29.1927 4.64058 29.3332 4.97972 29.3332 5.33334V12H2.6665V5.33334C2.6665 4.97972 2.80698 4.64058 3.05703 4.39053C3.30708 4.14049 3.64622 4.00001 3.99984 4.00001H9.33317V1.33334H11.9998V4.00001H19.9998V1.33334H22.6665V4.00001Z"
        fill="#3D9BE8"
      />
    </svg>
  );
};

const UpcomingTraining = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [loading, setLoading] = useState(true); // Loading state
  const userId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;

  const navigate = useNavigate();

  const getTrainingInfo = () => {
    axios
      .post("/getTrainingInfo.php", {
        userId: userId,
        userRole: userRole,
      })
      .then((res) => {
        const response = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTrainingInfo();
  }, []);

  // handle individual training details here
  const handleTrainingDetailsClick = (training) => {
    navigate(`/training-details/${training.id}`);
  };

  // Filtered training list based on search query
  const filteredData = searchQuery
    ? data.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : data;

  return (
    <>
      <div className="card-title">
        <div className="title">
          <h5>Upcoming Trainings</h5>
        </div>
      </div>
      {/* Material-UI Search Box */}
      <div className="search-box">
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search for trainings..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="training-list">
        {loading ? (
          <></>
        ) : filteredData.length > 0 ? (
          filteredData.map((item) => {
            const isOverdue = item.days_until_end < 0;
            const isUpcoming =
              item.days_until_end >= 0 && item.days_until_end <= 3;

            const trainingType = item.training_type_c;

            const status =
              trainingType === "Required"
                ? isOverdue
                  ? "overdue"
                  : isUpcoming
                  ? "upcoming"
                  : "scheduled"
                : "optional";

            const statusIcon =
              trainingType === "Required" ? (
                isOverdue ? (
                  <OverdueIcon />
                ) : isUpcoming ? (
                  <UpcomingIcon />
                ) : (
                  <ScheduledIcon />
                )
              ) : (
                <OptionalIcon />
              );

            const isdisabled = item.locked;

            const hours_until_unlock = item.hours_until_unlock;
            const pluralSuffix = hours_until_unlock > 1 ? "hrs" : "hr";

            return (
              <div
                style={{ cursor: !isdisabled ? "pointer" : "default" }}
                onClick={
                  !isdisabled
                    ? () => handleTrainingDetailsClick(item)
                    : undefined
                }
                className={`list-item ${status} ${
                  isdisabled ? "disabled" : ""
                }`}
                key={item.id}
              >
                <div className="list-item-wrapper">
                  <div className="status-icon">
                    {isdisabled ? <LockIcon /> : statusIcon}
                  </div>
                  <div className="list-item-content">
                    <div className="title">{item.name}</div>
                    <div className="bottom-wrapper">
                      {trainingType === "Required" && (
                        <>
                          <div className="date">
                            {moment(item.end_date_c).format("MMMM DD, YYYY")}
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C13.11 14 14 13.11 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z"
                              fill="#666666"
                            />
                          </svg>
                        </>
                      )}
                      <div className="status">
                        {isdisabled
                          ? `Next Attempt Unlocks in ${hours_until_unlock} ${pluralSuffix}`
                          : status}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list-item-icon">
                  <FaChevronRight size={20} />
                </div>
              </div>
            );
          })
        ) : (
          <p>No trainings match your search.</p>
        )}
      </div>
    </>
  );
};

export default UpcomingTraining;
