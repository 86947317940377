import React from "react";
const Error = () => {
  return (
    <>
      <div id="error-page" className="text-center">
        <div className="err-container">
          <h5> OOPS!</h5>
          <h1> 404 </h1>
          <p> Yeh kahaaaaan aa gaye hum... HRM pe bhatakte bhatakte !</p>

          <a href="/dashboard"> Go Back! </a>
        </div>
      </div>
    </>
  );
};
export default Error;
