import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { BsBackspaceFill } from "react-icons/bs";
import { LoginManageContext } from "./../App";

const TrainingDetails = () => {
    const [trainingDetails, setTrainingDetails] = useState({});
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const userId = localStorage.getItem("userId");
    const { loginData } = useContext(LoginManageContext);
    const userRole = loginData.userData.user_role_c;
    const { id: trainingId } = useParams();

    const handleAssessment = () => {
        navigate(`/training-assessment`, {
            state: { trainingId: trainingId },
        });
    };

    const getTrainingInfo = () => {
        axios
            .post("/getTrainingInfo.php", {
                userId: userId,
                userRole: userRole,
            })
            .then((res) => {
                const allTrainings = res.data;
                const filteredTraining = allTrainings.find(
                    (training) => training.id === trainingId
                );
                setTrainingDetails(filteredTraining);
                console.log(filteredTraining);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (trainingId) {
            getTrainingInfo();
        }
    }, [trainingId]);

    useEffect(() => {
        if (trainingId) {
            // Fetch the assessment questions count using the training ID
            axios
                .post("/getTrainingQuestionCount.php", { trainingId })
                .then((res) => {
                    const response = res.data;
                    console.log(response);
                    setCount(response.questionCount);
                })
                .catch((err) => console.error(err));
        }
    }, [trainingId]);


    return (
        <div className="main">
            <Sidebar />
            <div id="primary">
                <Header />
                <div className="main-view">
                    <div className="view-head">
                        <h6>Training Details</h6>
                        <NavLink to="/training">
                            {" "}
                            <span className="view_back_btn">
                                Back <BsBackspaceFill />
                            </span>
                        </NavLink>
                    </div>
                    <div className="training-details">
                        <div className="training-details-img">
                            <div className="bg-title">{trainingDetails?.name}</div>
                        </div>
                        <div
                            className="training-description"
                            dangerouslySetInnerHTML={{
                                __html: trainingDetails?.descriptionHtml,
                            }}
                        ></div>

                        <div className="cta-btn-wrapper">
                            <a
                                href={trainingDetails?.training_url_c}
                                target="_blank"
                                className="training-link-btn"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M10 6V8H5V19H16V14H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V7C3 6.73478 3.10536 6.48043 3.29289 6.29289C3.48043 6.10536 3.73478 6 4 6H10ZM21 3V11H19V6.413L11.207 14.207L9.793 12.793L17.585 5H13V3H21Z"
                                        fill="white"
                                    />
                                </svg>
                                Go to Learning Module
                            </a>
                            {count > 0 && !trainingDetails?.locked && (
                                <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleAssessment()}
                                    className="training-link-btn orange-btn"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M12 2C6.47699 2 1.99999 6.477 1.99999 12C1.99999 17.523 6.47699 22 12 22H22L19.071 19.071C20.001 18.1434 20.7385 17.0413 21.2411 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22 6.477 17.523 2 12 2ZM7.99999 13H16C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92171 16.5786 9.17157 15.8284C8.42142 15.0783 7.99999 14.0609 7.99999 13Z"
                                            fill="white"
                                        />
                                    </svg>
                                    Attempt Quiz
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingDetails;
