import React from "react";

function Leave(props) {
 const leaveInfo=props.leaveInfoUser;
  return (
    <div>
    
      <ul className="leave-list">
        <li className="leave-details">
          <p className="leave-name">Total</p>
          <span className="leave-dt tt"> {leaveInfo.length>0?leaveInfo[0].total_leave:0} </span>
        </li>
        <li className="leave-details">
          <p className="leave-name"> Balance </p>
          <span className="leave-dt bl"> {leaveInfo.length>0?leaveInfo[0].total_leave-leaveInfo[0].approved_leave:0} </span>
        </li>
        <li className="leave-details">
          <p className="leave-name"> Waiting For Approval </p>
          <span className="leave-dt ap">  {leaveInfo.length>0?leaveInfo[0].unapproved_leave!=='' && leaveInfo[0].unapproved_leave!==null?leaveInfo[0].unapproved_leave=='0.0'?0:leaveInfo[0].unapproved_leave:0:0} </span>
        </li>
      </ul>
    </div>
  );
}

export default Leave;
