import React from "react";
// import { MdOutlineAddCircleOutline } from "react-icons/md";
// import { MdModeEdit } from "react-icons/md";
// import { MdDelete } from "react-icons/md";
// import { Col, Container, Row, Modal } from "react-bootstrap";
// import axios from "axios";
function Trello(props) {
  return (
    <div>
      <div className="my-2 d-flex justify-content-between">
        {/* <p> Today's Task List </p>
        <p className="add-task">
          <span>
            <MdOutlineAddCircleOutline />
          </span>

          <button onClick={modalOpen}>Add Task</button>
        </p> */}
      </div>
      <div className="trello-task-tbl">
        {props.latestAssignments.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th className="trello-client">Client</th>
                <th className="trello-board">Board</th>
                <th className="trello-task"> Task </th>
                <th className="trello-description"> Description </th>
                {/* <th className="trello-action"> Action </th> */}
              </tr>
            </thead>
            <tbody>
              {props.latestAssignments.length > 0
                ? props.latestAssignments.map((row, index) => (
                    <tr>
                      <td>{row.clientName}</td>
                      <td>{row.boardName}</td>

                      <td>{row.name}</td>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {row.desc.substr(0, 80) + "..."}
                        <a
                          target="_blank"
                          href={row.url}
                          className="latest-read-more"
                        >
                          Read More
                        </a>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        ) : (
          <div className="norecords-display"> No Records to Display </div>
        )}
      </div>
    </div>
  );
}

export default Trello;
