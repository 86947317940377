import React, { useContext, useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { BsBackspaceFill } from "react-icons/bs";
import { LoginManageContext } from "../App";

const CtaxReport = () => {
  const [projects, setProjects] = useState({});

  const getAllClientInfo = async () => {
    const res = await axios.post("/getAllTrelloBoardApi.php", {
      clientId: "3ea3ac7e-9cb2-8b2d-8763-5fb78c8b166b",
    });
    const response = await res.data.result;
    setProjects(response);
  };

  useEffect(() => {
    getAllClientInfo();
  }, []);

  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="teams" className="main-view">
            <div className="view-head">
              <h6>CTAX Reports</h6>
              <NavLink to="/reports" activeClassName="hello">
                {" "}
                <span className="view_back_btn">
                  Back <BsBackspaceFill />
                </span>
              </NavLink>
            </div>
            <div class="team-wrapper">
              <div class="team-grid">
                <table>
                  <thead>
                    <tr>
                      <th className="name">Name </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.length > 0
                      ? projects.map((row, index) => (
                          <tr>
                            <td>
                              <span className="team-mate">
                                <div className="emp-details">
                                  <NavLink
                                    to={`/ctax-report/${row.Board_ID2}`}
                                    state={{ clientInfo: row?.Name0 }}
                                    activeClassName="hello"
                                  >
                                    <a className="team-emp-name">
                                      {row?.Name0}
                                    </a>
                                  </NavLink>
                                </div>
                              </span>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CtaxReport;
