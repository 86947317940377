import React, { useState, useEffect, useContext } from "react";
//import { useLocation } from "react-router-dom";
import { LoginManageContext } from "./../App";
const fulldate = () => {
  let dd = new Date();
  let month = dd.toLocaleString("en-US", { month: "long" });
  let dt = dd.toLocaleString("en-US", { day: "2-digit" });
  let yr = dd.getFullYear();
  let fdate = `${month} ${dt}, ${yr}`;
  return fdate;
};

const greet = () => {
  let dd = new Date();
  let hrs = dd.getHours();
  // let hrs = 13;
  // let am_pm = hrs > 12 ? "PM" : "AM";

  if (hrs === 0 || hrs < 12) {
    return "Good Morning";
  } else if (hrs >= 21) {
    return "Good Night";
  } else if (hrs >= 16) {
    return "Good Evening";
  } else {
    return "Good Afternoon";
  }
};

//const trelloTickets = 5;

const greetMsg = (trelloTickets) => {
  let dd = new Date();
  let hrs = dd.getHours();

  let day = dd.getDay();

  // hrs = 21;

  // day = 5;

  if (day === 0 || day === 6) {
    return " HAPPY WEEKEND !!! :) ";
  } else if ((hrs === 0 || hrs < 12) && typeof trelloTickets !== "undefined") {
    return `You have ${trelloTickets} new trello tickets assigned, Woah ! There is alot to do today. Let's begin!`;
  } else if ((hrs === 0 || hrs < 12) && typeof trelloTickets === "undefined") {
    return ``;
  } else if (hrs >= 21) {
    return "It's been a long day! Phewww... Time to shutdown and have some rest now. Take care :) ";
  } else if (hrs >= 19) {
    return "Done for the day? Don't forget to update the trello tickets and PUNCH OUT ! NOTE : Please update the trello labels.";
  } else if (hrs >= 17) {
    return "Looks like you have finished all your assigned tasks. If not, there is still time to Punch Out, mind finishing a little more work before that ? ";
  } else {
    return "What made you come here in the middle of your work? Hmm...!";
  }
};

const Greetings = (props) => {
  const [dt, setDt] = useState();
  // const userContext = useContext(LoginManageContext);
  // var userData = userContext.userInfo.userData;
  //const uId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  var userData = loginData.userData;
  const setGreetings = () => {
    setDt(fulldate(), greet());
  };
  useEffect(() => {
    setGreetings();
  }, []);

  return (
    <div id="welcome" className="grid-dashlet">
      <div className="d-flex justify-content-between align-content-center">
        <p className="greetings">
          <span className="greet-text"> {greet()}</span>, {userData.first_name}{" "}
          !
        </p>
        <p className="fullDate"> {fulldate()}</p>
      </div>
      <p>{greetMsg(props.latestAssignments.length)}</p>
      <button className="trello">
        <a href="https://trello.com/" rel="noreferrer" target="_blank">
          Trello
        </a>
      </button>
    </div>
  );
};

export default Greetings;
