import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  MdSpaceDashboard,
  MdOutlineSpaceDashboard,
  MdTaskAlt,
  MdOutlineTimer,
  MdOutlineRateReview,
  MdOutlineLocalPolice,
} from "react-icons/md";
import { SiMicrosoftteams } from "react-icons/si";
import { FaUserCog } from "react-icons/fa";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbMail,
  TbPhone,
  TbBeach,
  TbReportAnalytics,
  TbLogout,
  TbChecklist,
} from "react-icons/tb";
import { LoginManageContext } from "../App";
import logo from "../Images/nbl_logo.png";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;
  var stateInfo = loginData;

  const pmRoutes =
    userRole === "PM" || userRole === "Tester"
      ? [{ path: "/training", name: "Training", icon: <TbChecklist /> }]
      : [];

  const routes =
    userRole === "TL" ||
      userRole === "CEO" ||
      userRole === "HR" ||
      userRole === "PM" ||
      userRole === "BA" ||
      userRole === "Tester"
      ? [
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: <MdOutlineSpaceDashboard />,
        },
        {
          path: "/manage-tasks",
          name: "Manage Tasks",
          icon: <MdTaskAlt />,
        },
        {
          path: "/team-members",
          name: "Team Members",
          icon: <SiMicrosoftteams />,
        },
        {
          path: "/employee-info",
          name: "Personal Details",
          icon: <FaUserCog />,
        },
        {
          path: "/records",
          name: "Attendance Records",
          icon: <MdOutlineTimer />,
        },
        {
          path: "/holiday",
          name: "Holidays",
          icon: <TbBeach />,
        },
        {
          path: "/quarterly-self-review",
          name: "Quaterly Self Review",
          icon: <MdOutlineRateReview />,
        },
        {
          path: "/leave-policy",
          name: "Leave",
          icon: <TbLogout />,
        },
        {
          path: "/terms",
          name: "Terms & Policies",
          icon: <MdOutlineLocalPolice />,
        },
        {
          path: "/reports",
          name: "Reports",
          icon: <TbReportAnalytics />,
        },
        ...pmRoutes,
      ]
      : [
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: <MdOutlineSpaceDashboard />,
        },
        {
          path: "/manage-tasks",
          name: "Manage Tasks",
          icon: <MdTaskAlt />,
        },
        {
          path: "/team-members",
          name: "Team Members",
          icon: <SiMicrosoftteams />,
        },
        {
          path: "/employee-info",
          name: "Personal Details",
          icon: <FaUserCog />,
        },
        {
          path: "/records",
          name: "Attendance Records",
          icon: <MdOutlineTimer />,
        },
        {
          path: "/holiday",
          name: "Holidays",
          icon: <TbBeach />,
        },
        {
          path: "/quarterly-self-review",
          name: "Quaterly Self Review",
          icon: <MdOutlineRateReview />,
        },
        {
          path: "/leave-policy",
          name: "Leave",
          icon: <TbLogout />,
        },
        {
          path: "/terms",
          name: "Terms & Policies",
          icon: <MdOutlineLocalPolice />,
        },
      ];

  const openMail = (e, mail) => {
    e.preventDefault();
    window.location.href = `mailto:${mail}`;
  };

  const openPhone = (e, phone) => {
    e.preventDefault();
    window.location.href = `tel:${phone}`;
  };

  return (
    <div
      className={
        isSidebarOpen ? "sidebar-container active-sidebar" : "sidebar-container"
      }
    >
      <div className="sidebar-top-section">
        <img src={logo} alt="logo" height="30px" width="100px" />
      </div>
      <div className="sidebar-employee-section">
        <img
          src={
            stateInfo.userData.image !== "" && stateInfo.userData.image !== null
              ? "https://crm.nablasol.net/fromAPI/" + stateInfo.userData.image
              : stateInfo.userData.photo_url
          }
          alt={
            stateInfo.userData.first_name + " " + stateInfo.userData.last_name
          }
          height="100px"
          width="100px"
        />
        <div className="sidebar-employee-name">
          {`${stateInfo.userData.first_name} ${stateInfo.userData.last_name}`}
        </div>
        <div className="sidebar-employee-designation">
          {stateInfo.userData.designation}
        </div>
        <div className="sidebar-employee-contact-icons">
          <div
            className="sidebar-employee-contact-icon"
            title={stateInfo.userData.companyemail}
            onClick={(e) => openMail(e, stateInfo.userData.companyemail)}
          >
            <TbMail />
          </div>
          <div
            className="sidebar-employee-contact-icon"
            title={stateInfo.userData.telephone}
            onClick={(e) => openPhone(e, stateInfo.userData.telephone)}
          >
            <TbPhone />
          </div>
        </div>
      </div>
      <section className="sidebar-list-section">
        {routes.map((route, index) => (
          <NavLink
            to={route.path}
            key={index}
            className={({ isActive }) =>
              isActive ? "active-sidebar-list sidebar-list" : "sidebar-list"
            }
          >
            <div className="sidebar-list-icon">{route.icon}</div>
            <div className="sidebar-list-title">{route.name}</div>
          </NavLink>
        ))}
      </section>
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="sidebar-collapse-icon"
      >
        {isSidebarOpen ? (
          <TbLayoutSidebarLeftCollapse />
        ) : (
          <TbLayoutSidebarRightCollapse />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
