import React from "react";
//import pic from "/img/avtar.png";

function BDay(props) {
  const d = new Date();
  const month = d.toLocaleString("en-US", { month: "short" });
  const date = d.getDate();
  const dateCheck = date + " " + month;

  return (
    <div>
      <ul className="bday-list">
        {props.latestBirthday.length > 0
          ? props.latestBirthday.map((row, index) => (
              <li>
                <div className="emp-details">
                  <img
                    className="empBday"
                    // src={
                    //   row.image !== "" && row.image !== null
                    //     ? "https://crm.nablasol.net/fromAPI/" + row.image
                    //     : row.gender === "Male"
                    //     ? "https://crm.nablasol.net/fromAPI/male.png"
                    //     : "https://crm.nablasol.net/fromAPI/female.png"
                    // }
                    src={
                      row.image !== "" && row.image !== null
                        ? "https://crm.nablasol.net/fromAPI/" + row.image
                        : row.gender === "Male"
                        ? "https://crm.nablasol.net/fromAPI/male.png"
                        : "https://crm.nablasol.net/fromAPI/female.png"
                    }
                    alt={
                      row.first_name.charAt(0).toUpperCase() +
                      row.first_name.slice(1).toLowerCase()
                    }
                  />
                  <p>
                    <p className="emp_bday_wrap">
                      <span>
                        {row.first_name.charAt(0).toUpperCase() +
                          row.first_name.slice(1).toLowerCase()}
                        {props.getDateFormate(row.date_of_birth, true) ===
                        dateCheck ? (
                          <img
                            src="/img/hrm-giftbox.png"
                            alt="bday-popper"
                            className="bday-popper"
                          />
                        ) : (
                          ""
                        )}{" "}
                      </span>
                      <span className="emp-bday-dt">
                        {props.getDateFormate(row.date_of_birth, true)}{" "}
                      </span>
                    </p>
                    <span className="bemp-designation"> {row.designation}</span>
                  </p>
                </div>
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
}

export default BDay;
