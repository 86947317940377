import React, { useState, useEffect } from "react";
import axios from "axios";

const CompletedTraining = () => {
    const [data, setData] = useState([]);

    const userId = localStorage.getItem("userId");
    const getCompletedTrainingList = () => {
        axios
            .post("/getCompletedTrainingList.php", {
                userId: userId,
            })
            .then((res) => {
                const response = res.data;
                // console.log(response);
                setData(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getCompletedTrainingList();
    }, []);

    return (
        <>
            <div className="card-title">
                <div className="title-sort-wrapper">
                    <h5>Completed Trainings</h5>
                </div>
            </div>

            <div className="training-list">
                {data.map((item, id) => {
                    return (
                        <a href={item.training_url_c} target="_blank" key={id}>
                            <div className={`list-item`}>
                                <div className="list-item-wrapper">
                                    <div className="status-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                        >
                                            <path
                                                d="M15.9998 2.66669C8.6665 2.66669 2.6665 8.66669 2.6665 16C2.6665 23.3334 8.6665 29.3334 15.9998 29.3334C23.3332 29.3334 29.3332 23.3334 29.3332 16C29.3332 8.66669 23.3332 2.66669 15.9998 2.66669ZM15.9998 26.6667C10.1198 26.6667 5.33317 21.88 5.33317 16C5.33317 10.12 10.1198 5.33335 15.9998 5.33335C21.8798 5.33335 26.6665 10.12 26.6665 16C26.6665 21.88 21.8798 26.6667 15.9998 26.6667ZM22.1198 10.1067L13.3332 18.8934L9.87984 15.4534L7.99984 17.3334L13.3332 22.6667L23.9998 12L22.1198 10.1067Z"
                                                fill="#4BB543"
                                            />
                                        </svg>
                                    </div>

                                    <div className="list-item-content">
                                        <div className="title">{item.name}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </>
    );
};

export default CompletedTraining;
