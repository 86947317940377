import React, { useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import TrainingListFaq from "./TrainingListFaq";
import UpcomingTraining from "./UpcomingTraining";
import CompletedTraining from "./CompletedTraining";
import QuoteCarousel from "./QuoteCarousel";
import UpcomingTrainingAnnouncement from "./UpcomingTrainingAnnouncement";

const TrainingModule = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <Sidebar />
      <div id="primary">
        <Header />
        <div id="training" className="main-view">
          <div className="view-head">
            <h6>Training Module</h6>
          </div>
          <div className="upcoming-training-announcement">
            <UpcomingTrainingAnnouncement />
          </div>
          <div className="view-grid training-wrapper">
            <div className="training-info-left">
              <div className="upcoming-training training-card">
                <UpcomingTraining />
              </div>
            </div>
            <div className="training-info-right">
              <div className="completed-training training-card">
                <CompletedTraining />
              </div>
              <div className="quote-slider">
                <QuoteCarousel />
              </div>
            </div>
          </div>
          {/* <div className="training-card">
            <div className="card-title">
              <h5>Standard Guidelines</h5>
            </div>
            <TrainingListFaq />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TrainingModule;
