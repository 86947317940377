import React, { useEffect, useState } from "react";
import axios from "axios";

const CommentBox = ({ wishId, comment }) => {
  const [showComment, setShowComment] = useState([]);
  const [commentCount, setCommentCount] = useState(0);

  const fetchComments = async () => {
    const res = await axios.post(
      "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/getBdayCommentApi.php",
      JSON.stringify({ wishId: wishId })
    );
    setShowComment(res.data.result);
    setCommentCount(res.data.commentCounts);
  };
  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <h3>
        {commentCount} {commentCount > 1 ? "comments" : "comment"}
      </h3>
      {showComment.map((comment) => {
        return (
          <div className="comment-wrapper" key={wishId}>
            <div className="comment">
              <h6 className="comment-by">{comment.userDet.first_name}</h6>
              <p>1s </p>
            </div>
            <p className="comment-text">{comment.comment}</p>
          </div>
        );
      })}
    </>
  );
};

export default CommentBox;
