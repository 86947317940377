import React, { useState, useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import Alert from "./Alert";
import "react-datepicker/dist/react-datepicker.css";
import { DashCards } from "./Dashboard";
import { DashHoliday } from "./Holiday";
import Header, { getDateFormate } from "./Header";
import Sidebar from "./Sidebar";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { GiCancel } from "react-icons/gi";
import axios from "axios";
import { LoginManageContext } from "./../App";
import Leave from "../Images/leave.png";
function LeaveP1(props) {
  return (
    <div className="leave-info">
      <h5> {props.label} </h5>
      <span className={props.name}> {props.val} </span>
    </div>
  );
}

function LeaveAcc(props) {
  // const [isActive, setIsActive] = useState(false);
  const isActive = props.isOpen === props.accName;
  // var i = 0;
  return (
    <div className={`leave-acc grid-dashlet ${isActive ? "active" : ""}`}>
      <div
        className="leave-acc-head"
        onClick={() => props.setIsOpen(isActive ? "" : props.accName)}
      >
        <h5> {props.accName} </h5>
        <span className="toggle-icon">
          {isActive ? <TiArrowSortedDown /> : <TiArrowSortedUp />}
        </span>
      </div>
      {isActive && <div className="leave-acc-body">{props.children}</div>}
    </div>
  );
}

function NoticeBoard(props) {
  const renderHTML = (string) =>
    React.createElement("div", { dangerouslySetInnerHTML: { __html: string } });

  return (
    <div>
      {/* {props.leave.length > 0
        ? props.leave[0].leavePolicy.map((row, index) => (
          <>
            <h6> {row.name} </h6>
            <div id={"policy_desc" + index}>
              {renderHTML(row.leave_policy_description)};
            </div>
          </>
        ))
        : ""} */}
      <ul>
        <li>Leave Calendar is from January to December.</li>
        <li>The Due Date for Salary payment is the 7th of every month.</li>
        <li>
          Leave should be applied in advance at least before 7 to 15 Days (not
          applicable for exceptional circumstances).{" "}
        </li>
        <li>
          Leaves can be Unapproved for any reason. Approved leaves can also be
          revoked if circumstances change.
        </li>
        <li>
          Any leave availed without permission/intimation will be treated as
          absenteeism (hence no salary is payable for this period) and can lead
          to disciplinary action.
        </li>
        <li>
          If your leave is for 5 or more continuous calendar days, all weekends
          and other holidays during that period will be counted towards your
          leave count.
        </li>
        <li>
          If you are not present for at-least 3 days in a week, the weekend for
          that week will be considered a leave too.
        </li>
        <li>
          The unused leave can be carried forward to next year with up to 5
          leaves.
        </li>
        <li>
          If you consume extra leaves in a year it can be adjusted to the next
          year with a maximum of 5 count.
        </li>
      </ul>

      <h6> Quarterly Self-Review </h6>
      <ul>
        <li>
          You are supposed to submit a self-review of what you accomplished
          every quarter (Jan-Mar, Apr-June, July-Sep, Oct-Dec).
        </li>
        <li>The review should include the following:</li>
        <ul>
          <li>Projects/Tasks Completed</li>
          <li>Special Accomplishments/Learnings</li>
        </ul>
      </ul>
    </div>
  );
}

const SecurityGuidelines = () => {
  return (
    <div>
      <h6>Data Security Controls </h6>
      <ul>
        <li>
          You are personally accountable for all network and systems access
          under your user ID, so keep your password absolutely secret. Never
          share it with anyone, not even members of your family, friends, or IT
          staff.
        </li>
        <li>
          Corporate laptops are provided for official use by authorized
          employees. Do not loan your laptop or allow it to be used by others
          such as family and friends. Avoid leaving your laptop unattended and
          logged on. Always shut down, log off or activate a password-protected
          screensaver before walking away from the machine.
        </li>
        <li>
          No offline/online gaming or game installation should be done unless
          it's needed for the work being performed.
        </li>
        <li>
          Users cannot install any pirated/cracked software on the company's
          system or use any illegal torrents or transfer software.
        </li>
        <li>
          chrome plugins: install only permitted plugins (need R&D)
          <ul>
            <li>Allowed Plugins are: Passbolt, Grammarly, Loom, Postman</li>
          </ul>
        </li>
        <li>
          Only use encrypted Wifi, open Wifi networks can comprise security and
          data, WPA3 is the newer - and best - encryption available
        </li>
        <li>
          VPN use is a must: VPNs encrypt your internet traffic and disguise
          your online identity. This makes it more difficult for third parties
          to track your activities online and steal data.
        </li>
        <li>
          Be alert for phishing emails. Phishing emails are fraudulent emails
          that appear to be from a legitimate company or organization but are
          actually sent by cybercriminals in an attempt to steal sensitive.
        </li>
      </ul>
      <h6>Data Backups</h6>
      <li>
        You will be personally responsible for storing your data in Google
        drive.{" "}
      </li>
      <li>
        Remember, if the laptop is stolen, lost, or damaged, or if it simply
        malfunctions, it may be impossible to retrieve any of the data from the
        laptop. Saving the data in Google drive will save your work.
      </li>
    </div>
  );
};

function RLeave(props) {
  const [show, setShow] = useState(false);
  const [leaveInfo, setLeaveInfo] = useState({});
  const modalOpen = (lId, fromDate, toDate) => {
    setLeaveInfo({
      ...leaveInfo,
      lId: lId,
      fromDate: fromDate,
      toDate: toDate,
    });
    // const leaveId=lId;
    // const leaveFromDate=fromDate;
    // const leaveToDate=toDate;
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  // setAlertMsg({
  //   ...alertmsg,
  //   msg: "Leave Applied Successfully.",
  //   errorStatus: "success",
  //   alertText: "on",
  // });

  return (
    <>
      {/* <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="req-cancel-lv"
        aria-labelledby="Leave Status"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5"> Request To Cancel Leave </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          {props.alertmsg.alertText === "on" ? (
              <Alert alertText={props.alertmsg.msg} />
            ) : (
              ""
            )}
            <form
              method="post"
              onSubmit={props.cancelLeave}
              className="cancel-leave"
              autocomplete="off"
            >
              <textarea
                rows="2"
                required
                name="reason"
                onChange={props.cancelReasonValue}
                placeholder="Reason to cancel leave..."
                className="form-control mt-4"
              ></textarea>
              <input type="hidden" id="lId" value={leaveInfo.lId} />
              <input type="hidden" id="fromDate" value={leaveInfo.fromDate} />
              <input type="hidden" id="toDate" value={leaveInfo.toDate} />
              <Modal.Footer>
                <button type="submit" className="nbl-btn cancel-lv-btn">
                  Submit
                </button>
              </Modal.Footer>
            </form>
          </Container>
        </Modal.Body>
      </Modal> */}
      <div className="leave-tbl">
        <table>
          <thead>
            <tr>
              <th className="leave-from"> From </th>
              <th className="leave-to"> To </th>
              <th className="leave-reason"> Reason </th>
              <th className="leave-action"> Action Taken </th>
            </tr>
          </thead>
          <tbody>
            {props.leave.length > 0
              ? props.leave[0].allLeaveInfo.map((row, index) =>
                  row.action_c.trim() === "WaitingforApproval" ? (
                    <tr>
                      <td> {row.leave_from_c} </td>
                      <td> {row.leave_to_c} </td>
                      <td> {props.renderText(row.reason_c)} </td>
                      <td>
                        Waiting for Approval
                        {/* {row.cancle_request_c != "1" ? (
                          <button
                            //  onClick={() => props.cancelLeave(row.lId,row.leave_from_c,row.leave_to_c)}
                            onClick={() =>
                              modalOpen(
                                row.lId,
                                row.leave_from_c,
                                row.leave_to_c
                              )
                            }
                            className="cancel-lv"
                            title="Cancel Leave"
                          >
                            <GiCancel />
                          </button>
                        ) : (
                          ""
                        )} */}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}

function LHistory(props) {
  //console.log("props", props);
  const [leaveInfo, setLeaveInfo] = useState({});
  const modalOpen = (lId, fromDate, toDate) => {
    setLeaveInfo({
      ...leaveInfo,
      lId: lId,
      fromDate: fromDate,
      toDate: toDate,
    });
    props.lhsetAlertMsg({
      ...props.alertmsg,
      msg: "",
      errorStatus: "",
      alertText: "",
    });
    props.lhSetShow(true);
  };

  const CompareDate = (a) => {
    const date = new Date(a); //yyyy-mm-dd
    const d1 = date.setDate(date.getDate() + 1);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    const d2 = new Date(today);
    if (d1 >= d2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal
        show={props.lhShow}
        onHide={props.modalCloseLh}
        dialogClassName="req-cancel-lv"
        aria-labelledby="Leave Status"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5"> Request To Cancel Leave </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {props.alertmsg.alertText === "on" ? (
              <Alert alertText={props.alertmsg} />
            ) : (
              ""
            )}
            <form
              method="post"
              onSubmit={props.cancelLeave}
              className="cancel-leave"
              autocomplete="off"
            >
              <textarea
                rows="2"
                required
                name="reason"
                defaultValue={props.cancelReason}
                onChange={props.cancelReasonValue}
                placeholder="Reason to cancel leave..."
                className="form-control mt-4"
                id="leave_text"
              ></textarea>
              <input type="hidden" id="lId" value={leaveInfo.lId} />
              <input type="hidden" id="fromDate" value={leaveInfo.fromDate} />
              <input type="hidden" id="toDate" value={leaveInfo.toDate} />
              <Modal.Footer>
                <button type="submit" className="nbl-btn cancel-lv-btn">
                  Submit
                </button>
              </Modal.Footer>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      <div className="leave-tbl">
        <table>
          <thead>
            <tr>
              <th className="leave-from"> From </th>
              <th className="leave-to"> To </th>
              <th className="leave-reason"> Reason </th>
              <th className="leave-days"> Days </th>
              <th className="leave-action"> Action Taken </th>
            </tr>
          </thead>
          <tbody>
            {props.leave.length > 0
              ? props.leave[0].allLeaveInfo.map((row, index) =>
                  row.action_c != "WaitingforApproval" ? (
                    <tr>
                      <td> {row.leave_from_c} </td>
                      <td> {row.leave_to_c} </td>
                      <td> {props.renderText(row.reason_c)}</td>
                      <td> {row.leave_count} </td>
                      <td>
                        {" "}
                        {row.action_c == "CancelApproved"
                          ? "Cancel Approved"
                          : row.action_c}{" "}
                        {(row.action_c === "Approved" ||
                          row.action_c === "PartiallyApproved") &&
                        row.cancle_request_c != "1" &&
                        CompareDate(row.leave_to_c) == true ? (
                          <button
                            //  onClick={() => props.cancelLeave(row.lId,row.leave_from_c,row.leave_to_c)}
                            onClick={() =>
                              modalOpen(
                                row.lId,
                                row.leave_from_c,
                                row.leave_to_c
                              )
                            }
                            className="cancel-lv"
                            title="Cancel Leave"
                          >
                            <GiCancel />
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}

function LeavePolicy() {
  const ref = useRef(null);
  const [alertmsg, setAlertMsg] = useState({
    msg: "",
    errorStatus: "",
    alertText: "",
    alertApplyText: "",
  });
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [leave, setLeaveInfo] = useState({});
  const [leaveInfo, setLeaveInfoDetails] = useState({});
  const [cancelReason, setCancelReason] = useState({});
  const [lhShow, lhSetShow] = useState(false);
  const [isOpen, setIsOpen] = useState("Policies & Terms");
  const { loginData, updateData } = useContext(LoginManageContext);
  const currentDateTime = new Date();
  var stateInfo = loginData;
  const uId = localStorage.getItem("userId");
  const [startDate, setStartDate] = useState(new Date());
  var cancelModel = false;
  //set value for cancel reason
  const cancelReasonValue = (e) => {
    setCancelReason(e.target.value);
  };
  const setValue = (e) => {
    const leaveType = e.target.value;
    if (leaveType == "First Half" || leaveType == "Second Half") {
      setLeaveInfoDetails({
        ...leaveInfo,
        [e.target.name]: e.target.value,
        reason: "",
        fromDate: "",
        toDate: "",
        uId: uId,
        name:
          stateInfo.userData.first_name + " " + stateInfo.userData.last_name,
        email: stateInfo.userData.companyemail,
      });
    } else {
      setLeaveInfoDetails({
        ...leaveInfo,
        [e.target.name]: e.target.value,
        uId: uId,
        name:
          stateInfo.userData.first_name + " " + stateInfo.userData.last_name,
        email: stateInfo.userData.companyemail,
      });
    }
  };
  const fromDate = (date) => {
    if (
      leaveInfo.leaveType == "First Half" ||
      leaveInfo.leaveType == "Second Half"
    ) {
      setLeaveInfoDetails({
        ...leaveInfo,
        fromDate: date,
        toDate: date,
        uId: uId,
      });
    } else {
      setLeaveInfoDetails({
        ...leaveInfo,
        fromDate: date,

        uId: uId,
      });
    }
  };

  // to date
  const toDate = (date) => {
    setLeaveInfoDetails({
      ...leaveInfo,
      toDate: date,
      uId: uId,
    });
  };
  const modalOpen = () => {
    setLeaveInfoDetails({
      ...leaveInfo,

      reason: "",
      fromDate: "",
      toDate: "",
      uId: uId,
      name: stateInfo.userData.first_name + " " + stateInfo.userData.last_name,
      email: stateInfo.userData.companyemail,
    });
    setShow(true);
  };
  const renderText = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const modalClose = () => {
    setAlertMsg({
      ...alertmsg,
      msg: "",
      errorStatus: "",
      alertText: "",
      alertApplyText: "",
    });
    setShow(false);
  };
  const removeChar = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  // to set user data in state

  // to apply leave
  const applyLeave = (e) => {
    e.preventDefault();
    document.getElementById("apply-leave").disabled = true;
    setLoader(true);
    // Make a request for a user with a given ID
    axios
      .post("/applyLeaveApi.php", leaveInfo)
      .then(function (response) {
        const res = response.data;
        //console.log(res.statusCode);
        if (res.statusCode === 200) {
          document.getElementById("apply-leave").disabled = false;
          setLoader(false);
          localStorage.setItem("userinfo", JSON.stringify(res.result));
          // alert("Leave Applied Successfully!");
          setAlertMsg(
            {
              ...alertmsg,
              msg: "Leave Applied Successfully.",
              errorStatus: "success",
              alertApplyText: "on",
            },
            3000
          );
          updateData(res.result);
          setLeaveInfoDetails({
            ...leaveInfo,
            fromDate: "",
            toDate: "",
            leaveType: "",
            reason: "",
          });
          ref.current.value = "";
          //setShow(false);
          getAllLeaveInfo();
        } else {
          // console.log("Oops something went wrong");
          setAlertMsg({
            ...alertmsg,
            msg: "Error Applying Leave.",
            errorStatus: "error",
            alertText: "on",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // to get all info of user
  const getAllLeaveInfo = () => {
    axios
      .post("/getAllLeaveInfoApi.php", { uId: uId })
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          setLeaveInfo(res.result);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //lh model
  const modalCloseLh = () => {
    lhSetShow(false);
  };

  // to cancel applied leave
  const cancelLeave = (e) => {
    e.preventDefault();
    const toDate = document.getElementById("toDate").value;
    const fromDate = document.getElementById("fromDate").value;
    const lId = document.getElementById("lId").value;
    var text = "Are you sure?";
    if (window.confirm(text) === true) {
      axios
        .post("/cancelLeaveApi.php", {
          fromDate: fromDate,
          toDate: toDate,
          uId: uId,
          lId: lId,
          name:
            stateInfo.userData.first_name + " " + stateInfo.userData.last_name,
          email: stateInfo.userData.companyemail,
          reason: cancelReason,
        })
        .then(function (response) {
          const res = response.data;
          if (res.statusCode === 200) {
            setCancelReason("");
            document.getElementById("leave_text").val = "";
            //cancelModel=false;
            // lhSetShow(false);
            // alert("Your leave cancellation request has been sent successfully");
            setAlertMsg({
              ...alertmsg,
              msg: "Leave cancellation requested.",
              errorStatus: "success",
              alertText: "on",
            });

            getAllLeaveInfo();
          } else {
            console.log("Oops something went wrong");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    getAllLeaveInfo();
  }, []);
  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="leave-policy" className="main-view">
            <div className="view-head">
              <h6>Leave</h6>
              <button onClick={modalOpen} className="nbl-btn">
                Leave Apply
              </button>
            </div>
            <div className="view-grid">
              <div className="leave-details">
                <LeaveP1
                  name="total"
                  label="Total"
                  val={
                    leave.length > 0 ? leave[0].leaveInfo[0].total_leave : "0"
                  }
                />
                <LeaveP1
                  name="bal"
                  label="Balance"
                  val={
                    leave.length > 0
                      ? leave[0].leaveInfo[0].total_leave -
                        leave[0].leaveInfo[0].approved_leave
                      : "0"
                  }
                />
                <LeaveP1
                  name="wait-app"
                  label="Waiting For Approval"
                  val={
                    leave.length > 0
                      ? leave[0].leaveInfo[0].unapproved_leave == "0.0"
                        ? 0
                        : leave[0].leaveInfo[0].unapproved_leave
                      : "0"
                  }
                />
              </div>
              <div className="up-leave">
                <DashCards
                  label="hday"
                  name="Upcoming Holidays"
                  link="/holiday"
                  linktext="View All"
                >
                  <DashHoliday
                    latestHoliday={stateInfo.latestHoliday}
                    getDateFormate={getDateFormate}
                  />
                </DashCards>
              </div>

              <LeaveAcc
                accName="Requested Leave"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <RLeave
                  cancelLeave={cancelLeave}
                  leave={leave}
                  renderText={renderText}
                />
              </LeaveAcc>
              <LeaveAcc
                accName="Leave History"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <LHistory
                  lhSetShow={lhSetShow}
                  alertmsg={alertmsg}
                  lhsetAlertMsg={setAlertMsg}
                  lhShow={lhShow}
                  modalCloseLh={modalCloseLh}
                  cancelLeave={cancelLeave}
                  leave={leave}
                  cancelReasonValue={cancelReasonValue}
                  renderText={renderText}
                />
              </LeaveAcc>
              {/* <LeaveAcc accName="Requested WFH">
          <Wfh leave={leave} />
        </LeaveAcc> */}
            </div>
            <Modal
              show={show}
              onHide={modalClose}
              dialogClassName="lv-application"
              aria-labelledby="Add Trello Task"
              backdrop="static"
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title as="h5">
                  <div className="modal_title_wrapper">
                    <img src={Leave} />
                    <div className="quote_sec">
                      <h3>We'll miss you.</h3>
                      <p>
                        Success is often achieved by those who don't take leave
                        from work.
                      </p>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <hr className="hr_line" />
              {alertmsg?.msg && (
                <div className="successMsg">
                  {alertmsg.alertApplyText == "on" ? (
                    <Alert alertText={alertmsg} className="alert_msg_" />
                  ) : (
                    ""
                  )}
                </div>
              )}
              {loader === true ? (
                <div className="loader">
                  <img src="/img/hrm-loader.svg" alt="loader" />
                </div>
              ) : (
                ""
              )}

              <form
                method="post"
                onSubmit={applyLeave}
                className="leave_application-form"
                autocomplete="off"
              >
                <Modal.Body>
                  <Container>
                    <div className="leave_type">
                      <label> Leave Type </label>
                      <select
                        ref={ref}
                        required
                        onChange={setValue}
                        name="leaveType"
                        className="form-control"
                      >
                        <option value=""> </option>
                        <option value="full day"> Full Day or Between </option>
                        <option value="first half"> First Half </option>
                        <option value="second half"> Second Half </option>
                      </select>
                    </div>
                    {leaveInfo.leaveType == "first half" ||
                    leaveInfo.leaveType == "second half" ? (
                      <Row>
                        <Col md={12} className="leave_date">
                          <label> Date </label>

                          <DatePicker
                            required
                            selected={leaveInfo.fromDate}
                            placeholder="From Date"
                            name="fromDate"
                            minDate={new Date()}
                            onChange={fromDate}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col md={12} className="leave_date">
                          <label> From </label>

                          <DatePicker
                            required
                            selected={leaveInfo.fromDate}
                            placeholder="From Date"
                            name="fromDate"
                            minDate={new Date()}
                            onChange={fromDate}
                          />
                        </Col>
                        <Col md={12} className="leave_date">
                          <label> To </label>

                          <DatePicker
                            selected={leaveInfo.toDate}
                            required
                            placeholder="To Date"
                            name="toDate"
                            minDate={
                              leaveInfo.fromDate != ""
                                ? leaveInfo.fromDate
                                : currentDateTime
                            }
                            onChange={toDate}
                          />
                        </Col>
                      </Row>
                    )}
                    <div className="reason_">
                      <label> Reason </label>
                      <textarea
                        rows="2"
                        required
                        name="reason"
                        placeholder=""
                        className="form-control"
                        onChange={setValue}
                        value={leaveInfo.reason}
                      ></textarea>
                    </div>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="nbl-btn lv-application"
                    id="apply-leave"
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeavePolicy;
