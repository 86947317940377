import React, { useContext, useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { NavLink } from "react-router-dom";
import { TiBook, TiThList } from "react-icons/ti";
import { BsFileSpreadsheetFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { ImUserTie } from "react-icons/im";
import { MdUpdate } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { LoginManageContext } from "./../App";

function Reports(props) {
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="rpt" className="main-view">
            <div className="view-head">
              <h6> Reports </h6>
            </div>
            <div className="view-grid">
              {/*<div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/work-report-aggregate`} className="report-title">
                      <span className="labelicon">
                        <TiBook />
                      </span>
                      <a>
                          Employee Work Report Aggregate
                      </a>
                  </NavLink>
                </div>
  </div>*/}
              {userRole !== "HR" ? (
                <div className="qsr-erp grid-dashlet" id="report-item">
                  <div className="qtr-wrapper">
                    <NavLink to={`/trello-tasks-clients`} className="report-title">
                      <span className="labelicon">
                        <ImUserTie />
                      </span>
                      <a>
                        Assigned Tasks By Clients
                      </a>
                    </NavLink>
                  </div>
                </div>
              ) : null}

              <div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/trello-tasks-employee`} className="report-title">
                    <span className="labelicon">
                      <HiUsers />
                    </span>
                    <a>
                      Assigned Tasks For Employee
                    </a>
                  </NavLink>
                </div>
              </div>

              <div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/client-timesheet`} className="report-title">
                    <span className="labelicon">
                      <BsFileSpreadsheetFill />
                    </span>
                    <a>
                      Current Month Client Timesheet
                    </a>
                  </NavLink>
                </div>
              </div>

              <div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/work-report-details`} className="report-title">
                    <span className="labelicon">
                      <TiThList />
                    </span>
                    <a>
                      Last 7 Days Emp Work Report
                    </a>
                  </NavLink>
                </div>
              </div>

              <div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/ctax-report`} className="report-title">
                    <span className="labelicon">
                      <TiThList />
                    </span>
                    <a>CTAX Report</a>
                  </NavLink>
                </div>
              </div>

              {/*<div className="qsr-erp grid-dashlet" id="report-item">
                <div className="qtr-wrapper">
                  <NavLink to={`/ctax-task-board`} className="report-title">
                    <span className="labelicon">
                      <TiThList />
                    </span>
                    <a>CTax Active Tasks</a>
                  </NavLink>
                </div>
              </div>*/}

              {userRole === "PM" || userRole === "Tester" ? (
                <div className="qsr-erp grid-dashlet" id="report-item">
                  <div className="qtr-wrapper">
                    <NavLink to={`/client-timesheet-ctax`} className="report-title">
                      <span className="labelicon">
                        <BsFileSpreadsheetFill />
                      </span>
                      <a>
                        Current Week Client Timesheet - CTax
                      </a>
                    </NavLink>
                  </div>
                </div>
              ) : null}
              {userRole === "PM" || userRole === "Tester" ? (
                <>
                  <div className="qsr-erp grid-dashlet" id="report-item">
                    <div className="qtr-wrapper">
                      <NavLink
                        to={`/upcoming-trainings-employee`}
                        className="report-title"
                      >
                        <span className="labelicon">
                          <MdUpdate />
                        </span>
                        <a>Upcoming Trainings Of Employee</a>
                      </NavLink>
                    </div>
                  </div>

                  <div className="qsr-erp grid-dashlet" id="report-item">
                    <div className="qtr-wrapper">
                      <NavLink
                        to={`/completed-trainings-employee`}
                        className="report-title"
                      >
                        <span className="labelicon">
                          <RxUpdate />
                        </span>
                        <a>Completed Trainings Of Employee</a>
                      </NavLink>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
