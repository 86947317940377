import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { LoginManageContext } from "./../App";

const UpcomingTrainingAnnouncement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const userId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;

  const getUpcomingTrainingAnnouncement = () => {
    axios
      .post("/upcomingTrainingAnnouncement.php", {
        userId: userId,
        userRole: userRole,
      })
      .then((res) => {
        const response = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUpcomingTrainingAnnouncement();
  }, []);

  return (
    <>
      {/* Announcement Marquee */}
      {!loading && data.length > 0 && (
        <div className="announcement-marquee">
          <marquee scrollamount="10">
            {data.map((training, index) => (
              <span key={index}>
                Upcoming Training: {training.name} on{" "}
                {moment(training.end_date_c).format("MMMM DD, YYYY")}{" "}
                {index < data.length - 1 && <span> | </span>}
              </span>
            ))}
          </marquee>
        </div>
      )}
    </>
  );
};

export default UpcomingTrainingAnnouncement;
