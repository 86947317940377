import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { BsBackspaceFill } from "react-icons/bs";
import { LoginManageContext } from "../App";
import Header from "./Header";
import Sidebar from "./Sidebar";

const TaskBoard = () => {
  const [loading, setLoading] = useState(false);
  const [boardContent, setBoardContent] = useState({});
  const [labelOptions, setLabelOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);

  const [labelFilterValue, setLabelFilterValue] = useState(null);
  const [priorityFilterValue, setPriorityFilterValue] = useState(null);
  const [memberFilterValue, setMemberFilterValue] = useState(null);

  const uId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;

  const getAllClientInfo = async () => {
    setLoading(true);
    const res = await axios.post("/getAllTrelloTaskBoardNewUI.php", {
      uId: uId,
      userRole: userRole,
      clientId: "3ea3ac7e-9cb2-8b2d-8763-5fb78c8b166b",
      label: labelFilterValue !== null ? labelFilterValue?.value : "",
      priority:
        priorityFilterValue !== null ? priorityFilterValue?.value : "",
      member: memberFilterValue !== null ? memberFilterValue?.value : "",
    });

    const response = await res?.data;
    setBoardContent(response?.result);

    setLabelOptions(
      response?.labels?.map((label) => ({
        label: label,
        value: label,
      }))
    );
    setMemberOptions(
      response?.members?.map((member) => ({
        label: member,
        value: member,
      }))
    );
    setPriorityOptions(
      response?.priority?.map((priority) => ({
        label: priority,
        value: priority,
      }))
    );

    setLoading(false);
  };

  useEffect(() => {
    getAllClientInfo();
  }, [labelFilterValue, memberFilterValue, priorityFilterValue]);

  const changeLabelFilterValueFunction = (selectedLabel) => {
    setLabelFilterValue(selectedLabel);
  };

  const changePriorityFilterValueFunction = (selectedPriority) => {
    setPriorityFilterValue(selectedPriority);
  };

  const changeMemberFilterValueFunction = (selectedMember) => {
    setMemberFilterValue(selectedMember);
  };

  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="teams" className="main-view trello-board-view">
            <div className="view-head trello-view-head">
              <h6>CTax Active Tasks</h6>
              <div className="board-filters-with-back-button">
                <Select
                  className="task-board-user-filter"
                  placeholder="Label"
                  onChange={changeLabelFilterValueFunction}
                  isClearable={true}
                  isSearchable={true}
                  name="label"
                  options={labelOptions}
                />
                <Select
                  className="task-board-user-filter"
                  placeholder="Priority"
                  onChange={changePriorityFilterValueFunction}
                  isClearable={true}
                  isSearchable={true}
                  name="priority"
                  options={priorityOptions}
                />
                <Select
                  className="task-board-user-filter"
                  placeholder="Member"
                  onChange={changeMemberFilterValueFunction}
                  isClearable={true}
                  isSearchable={true}
                  name="user"
                  options={memberOptions}
                />
                <NavLink to="/reports" activeClassName="hello">
                  {" "}
                  <span className="view_back_btn">
                    Back <BsBackspaceFill />
                  </span>
                </NavLink>
              </div>
            </div>
            {!loading ? (
              <div className="task-board-container">
                <div className="board-list">
                  {Object.entries(boardContent)?.map((labelData, index) => (
                    <div key={index} className="board-container">
                      <div className="board-title">{labelData[0]}</div>
                      <div className="board-content">
                        {labelData?.[1]?.length !== 0 ? (
                          labelData?.[1]?.map((cardData, index) => (
                            <a href={cardData?.cardURL} target="_blank">
                              <div key={index} className="board-card">
                                <div className="board-card-label-list">
                                  {cardData?.short
                                    ?.split(",")
                                    ?.map((label, index) => (
                                      <div className="board-card-label">
                                        {label}
                                      </div>
                                    ))}
                                </div>
                                <div className="board-card-heading">
                                  {cardData?.cardName}
                                </div>
                                <div className="board-card-assigned-member">
                                  {cardData?.members?.map(
                                    (imageList, index) => (
                                      <img
                                        src={`${imageList?.avatarUrl}/50.png`}
                                        title={imageList?.fullName}
                                        height="25px"
                                        width="25px"
                                        alt=""
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </a>
                          ))
                        ) : (
                          <div className="empty-board-list">
                            No In Progress Cards
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  height: "80vh",
                  width: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskBoard;
