import { useEffect, useState } from "react";
import Card from "./Card";
import axios from "axios";
const BdayWishes = () => {
  const [bdayMember, setBdayMember] = useState([]);

  const fetchBday = async () => {
    const res = await axios.get(
      "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/getAllWishesApi.php"
    );
    console.log(typeof res.data.result);
    setBdayMember(res.data.result);
  };

  useEffect(() => {
    fetchBday();
  }, []);

  console.log(bdayMember);

  return (
    <div className="container">
      <div className="card-box">
        {bdayMember.map((member) => {
          return (
            <Card
              key={member.wishDet.id_c}
              member_details={member}
              wishId={member.wishDet.id_c}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BdayWishes;
