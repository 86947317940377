import React, { useContext, useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { NavLink, useLocation, useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import FormControl from "@mui/material/FormControl";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsBackspaceFill } from "react-icons/bs";
import { LoginManageContext } from "../App";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

function CtaxProjectTasks() {
  const [mainloader, setMainLoader] = useState(true);
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [labels, setLabels] = useState({});
  const uId = localStorage.getItem("userId");

  const [selectedLabel, setSelectedLabel] = useState("");
  const handleLabelChange = (e) => {
    getTrelloTasksReportInfo(uId, projectId, userRole, e.target.value);
  };

  let i = 0;

  const columns = [

    {
      name: "List",
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: "70px" },
        }),
      },
    },

    "Task",
    {
      name: "Description",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "360px", maxWidth: "360px", overflow: "hidden" },
        }),
        customBodyRender: function (value, tableMeta, updateValue) {
          i = i + 1;
          return (
            <>
              <div id={i}>{value.substring(0, 100) + " ..."}</div>
              <ReactTooltip
                style={{ width: 600 }}
                anchorId={i}
                place="bottom"
                content={value}
              />
            </>
          );
        },
      },
    },
    {
      name: "Labels",
      options: {
        filter: false,
      },
    },
    {
      name: "Members",
      options: {
        filter: false,
      },
    },

    "Priority",
  ];

  const options = {
    sortOrder: {
      name: "Board",
      direction: "asc",
    },

    search: searchBtn,
    download: downloadBtn,
    downloadOptions: {
      filename: "TrelloTasksClient.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true | false,
        useDisplayedRowsOnly: true | true,
      },
    },
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  };

  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;

  const location = useLocation();
  const { projectId } = useParams();
  const [data, setData] = useState([]);
  const getTrelloTasksReportInfo = (uId, projectId, userRole, label) => {
    setMainLoader(true);
    axios
      .post("/getAllTrelloTaskBoard.php", {
        uId: uId,
        boardId: projectId,
        userRole: userRole,
        label: label,
      })
      .then((res) => {
        const response = res.data.result;
        let my_array = [];
        response.forEach((obj) => {
          let inside_array = [];
          Object.entries(obj).forEach(([key, value]) => {
            inside_array.push(`${value}`);
          });
          my_array.push(inside_array);
        });

        var allLabels = [];
        const responseLabels = res.data.labels;
        var allLabelsData = {
          label: 'All',
          value: '',
        };
        allLabels.push(allLabelsData);
        if (responseLabels) {
          for (var i = 0; i < responseLabels.length; i++) {
            var allLabelsData = {
              label: responseLabels[i].name,
              value: responseLabels[i].name,
            };
            allLabels.push(allLabelsData);
          }
        }

        setSelectedLabel(label);
        setLabels(allLabels);
        setData(my_array);
        setMainLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTrelloTasksReportInfo(uId, projectId, userRole, '');
  }, []);
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="reports" className="main-view">
            <div className="view-head">
              <h6> Assigned Tasks</h6>
              <NavLink to="/ctax-report" activeClassName="hello">
                {" "}
                <span className="view_back_btn">
                  Back <BsBackspaceFill />
                </span>
              </NavLink>
            </div>

            <div className="view-grid grid-dashlet">
              <div class="reports-wrapper with-date-filter">
                <h3 className="client_view_heading">
                  {location.state?.clientInfo}
                </h3>
                {mainloader === true ? (
                  <div className="loader">
                    <img src="/img/hrm-loader.svg" alt="loader" />
                  </div>
                ) : (
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={createTheme()}>
                      <FormControl class="date-filter" style={{ paddingTop: 50, width: 350 }}>
                        <label className="label-filter-label">Trello Labels</label>

                        <select
                          id="trelloLabels"
                          value={selectedLabel}
                          onChange={handleLabelChange}
                          className="form-control label-filter-select"
                        >
                          {labels.map((label) => {
                            return (
                              <option value={label.value}>
                                {" "}
                                {label.label}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                      <MUIDataTable
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CtaxProjectTasks;
