import React,{useEffect,useState} from "react";

function Alert(props) {
  const [show, setShow] = useState('block')
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow('none');
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    props.alertText && <div>
    {props.alertText.msg}
  </div>
  );
}

export default Alert;
