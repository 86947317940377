import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { NavLink,useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { createTheme,ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsBackspaceFill } from 'react-icons/bs';
var cId = '';
var empId = '';

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

function WorkReportDetails() {
  const [dateFrom, setDateFrom] = useState(new Date().toISOString().slice(0,10));
  const [dateTo, setDateTo] = useState(new Date().toISOString().slice(0,10));
  
  const handleDateFromChange = (e) => {
    setDateFrom(e.target.value);
    getDailyEntryReportInfo(uId,e.target.value,dateTo);
  };
  
  const handleDateToChange = (e) => {
    setDateTo(e.target.value);
    getDailyEntryReportInfo(uId,dateFrom,e.target.value);
  };

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  let i = 0;
 
  const columns = [
    { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
    "Clients",
    "Card Name",
    {name: "Description", options: {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { minWidth: "500px", maxWidth: "500px", overflow: "hidden" } }),  
    customBodyRender: function(value, tableMeta, updateValue) {i= i+1; return (<><div id={i} >{value.substring(0,100)+" ..."}</div><ReactTooltip style={{ width: 450 }} anchorId={i} place="bottom" content={value} /></>)}}},
    "Hours",
    "Date Created"
  ];

  const options = {
    sortOrder: {
      name: 'Employee Name',
      direction: 'asc'
    },
    
    search: searchBtn,
    download: downloadBtn,
    downloadOptions: {
      filename: 'WorkReportDetails.csv',
      separator: ',',  
      filterOptions: { useDisplayedColumnsOnly: true | false,
        useDisplayedRowsOnly: true | true },    
    },
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,    
  };

  
  const uId = localStorage.getItem("userId");

  const [data, setData] = useState([]);
  const getDailyEntryReportInfo = (uId,dateFrom,dateTo,empId) => {
    axios
        .post("/getDailyEntryReportApi.php", { uId: uId, dateFrom: dateFrom, dateTo: dateTo, empId: empId, action: "details"})
        .then((res) => {
            const response = res.data.result;
            if(response) {
              var my_array = [];             
              response.forEach(obj => {
                let inside_array = [];
                Object.entries(obj).forEach(([key, value]) => {
                    inside_array.push(`${value}`);
                });
                my_array.push(inside_array);
              });
            }  
            setData(my_array);         
        })
        .catch((err) => {
            console.log(err);
        });
  };

  useEffect(() => {
    getDailyEntryReportInfo(uId,'','','');
  }, []);
  return (
    <>
      {" "}
    <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="reports" className="main-view">
            <div className="view-head">
              <h6> Last 7 Days Emp Work Report </h6>
              <NavLink
                  to="/reports" 
                  activeClassName="hello"
              >
                  {" "}
                  <span className="view_back_btn">Back <BsBackspaceFill /></span>
              </NavLink>
            </div>            
            <div className="view-grid grid-dashlet">
              <div class="reports-wrapper with-date-filter">
                <CacheProvider value={muiCache}>
                  <ThemeProvider theme={createTheme()}>
                  <FormControl  class="date-filter" style={{ padding: 15 }}>
                      <label className="date-filter-label">Date From </label>
                      <input
                        type="date"
                        name="date_from"
                        onChange={handleDateFromChange}
                        required
                        className="form-control date-filter-date"
                      />         
                      <label className="date-filter-label">Date To </label>
                      <input
                        type="date"
                        name="date_to"
                        onChange={handleDateToChange}
                        required
                        className="form-control date-filter-date"
                      />                    
                    </FormControl>
                    <MUIDataTable
                      title={"Last 7 Days Emp Work Report"}
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </ThemeProvider>
                </CacheProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkReportDetails;